import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy,NavParams } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { CalendarModule } from "ion2-calendar";

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Modal Pages
import { ImagePageModule } from './pages/modal/image/image.module';
import { SearchFilterPageModule } from './pages/modal/search-filter/search-filter.module';
import { CalenderPageModule } from './pages/modal/calender/calender.module';

// Components
import { NotificationsComponent } from './components/notifications/notifications.component';

import { PopmenuComponent } from './components/popmenu/popmenu.component';
import { IonicSelectableModule } from 'ionic-selectable';
//DatePicker
//import { DatePicker } from '@ionic-native/date-picker/ngx';
//import { NgCalendarModule } from 'ionic2-calendar';

//====Providers
import { HttpModule, Http, RequestOptions, Response } from '@angular/http';
import { AuthHttp, AuthConfig, JwtHelper } from 'angular2-jwt';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './providers/auth.interceptor';

import { AuthService } from './providers/auth.service';
import { GenericService } from './providers/generic.service';
import { StorageService } from './providers/storage.service';
import { MasterService } from './providers/master.service';
import { ChartService } from './providers/chart.service';
import { FusionChartsService } from './providers/fusion-charts.service';
import { ChartjsService } from './providers/chartjs.service';
import { GlobalService } from './providers/global.service';
import { IonicService } from './providers/ionic.service';
import { UploadService } from './providers/upload.service';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TaskDetailsPageModule } from './pages/modal/task-details/task-details.module';
import { MatTabsModule } from '@angular/material/tabs';


export function getJwtHttp(http: Http, options: RequestOptions) {
    return new AuthHttp(new AuthConfig({
        tokenName: 'token',
        tokenGetter: (() => {
            var user = StorageService.get('user');
            if (user)
                return user.access_token;
        }),
        globalHeaders: [{ 'Content-Type': 'application/json' }],
    }), http, options);
}

@NgModule({
    declarations: [AppComponent, NotificationsComponent, PopmenuComponent],   
    imports: [
        MatTabsModule,
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        ImagePageModule,
        //NgCalendarModule,
        SearchFilterPageModule,
        TaskDetailsPageModule,
        CalenderPageModule,
        HttpModule,
        CalendarModule,
        IonicSelectableModule,
        NgxDatatableModule
    ],    
    entryComponents: [NotificationsComponent],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        AuthService,
        {
            provide: AuthHttp,
            useFactory: getJwtHttp,
            deps: [Http, RequestOptions]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        MasterService,
        ChartService,
        ChartjsService,
        FusionChartsService,
        GlobalService,
        IonicService,
        UploadService
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
