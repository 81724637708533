﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Platform, LoadingController, ToastController, AlertController, ModalController} from '@ionic/angular';
import { GenericService } from './generic.service';
import { GlobalService } from './global.service';
import { AuthService } from './auth.service';
import { LogLevel } from './enum';

@Injectable()
export class IonicService {
    isLoading = false;
    loader: any;
    subscription: any;
    searchText: string;
    dataFetcherEvent: any;

    constructor(private platform: Platform, private loadingCtrl: LoadingController, private toastCtrl: ToastController,
        public alertCtrl: AlertController, private modalCtrl: ModalController) {

    }

    async showLoader(text: string = null, allowCancel = false) {
        //if (this.loader)
        //    this.loader.setContent(text);
        //else {
        //    text = text || 'Please wait a moment...';
        //    if (allowCancel)
        //        this.loader = await this.loadingCtrl.create({ message: text, showBackdrop: true, duration: 5000 });
        //    else
        //        this.loader = await this.loadingCtrl.create({ message: text, duration: 5000 });
        //    await this.loader.present();
        //}
        this.hideLoader();
        this.isLoading = true;
        return await this.loadingCtrl.create({

        }).then(a => {
            a.present().then(() => {
                console.log('presented');
                if (!this.isLoading) {
                    a.dismiss().then(() => console.log('abort presenting'));
                }
            });
        });
    }

    async hideLoader() {
        // if (this.loader) {
        //     this.loader.dismiss();
        //     this.loadingCtrl.dismiss();
        //    this.loader = null;
        //}
        // this.loadingCtrl.getTop().then(v => v ? this.loadingCtrl.dismiss() : null);
        try {
            this.isLoading = false;
            return await this.loadingCtrl.dismiss().then(() => console.log('dismissed'));
        }
        catch (ex)
        {
        }
    }

    get spinner() {
        return GlobalService.spinner;
    }

    showSpinner() {
        GlobalService.spinner = true;
    }

    hideSpinner() {
        GlobalService.spinner = false;
    }

    async showToast(text, position: any = 'top', cssClass = null) {
        this.hideLoader();
        this.hideSpinner();
        var toast = await this.toastCtrl.create({
            message: text,
            duration: 3000,
            position: position,
            cssClass: cssClass
        });
        await toast.present();
    }
    async presentToast(msg) {
        const toast = await this.toastCtrl.create({
            message: msg,
            duration: 2000
        });
        toast.present();
    }

    showSuccessToast(text: string, position = null) {
        this.showToast(text, position, 'success-toast');
    }

    showErrorToast(err: any, position = null, errorLevel: LogLevel = null) {
        let msg = GenericService.fetchErrorMsg(err);
        this.showToast(msg, position, 'error-toast');
        //if (errorLevel != 0)
        //    this.ls.log(err);
    }

    async alert(text: string, cssClass = null) {
        this.hideLoader();
        this.hideSpinner();
        let obj: any = {
            message: text,
            buttons: ['Ok'],
        };
        if (cssClass)
            obj.cssClass = cssClass

        let alert = await this.alertCtrl.create(obj);
        await alert.present();
    }

    async alertSuccess(text: string) {
        await this.alert(text, 'alert-success');
    }

    async alertError(err: any, log = true) {
        let msg: string = GenericService.fetchErrorMsg(err);
        if (msg.startsWith('ServerError: ')) {
            msg = msg.replace('ServerError: ', '');
            log = false;
        }
        await this.alert(msg, 'alert-error');
        //if (log)
        //    this.ls.log(err);
    }

    async setDefault() {
        try {
            await this.modalCtrl.dismiss();
        }
        catch
        {
        }
        //const modal = this.modalCtrl.getTop();
        //if (modal) {
        //    modal.dismiss();
        //}
    }

}
