import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CalendarComponentOptions  } from 'ion2-calendar';

@Component({
    selector: 'app-calender',
    templateUrl: './calender.page.html',
    styleUrls: ['./calender.page.scss'],
})
export class CalenderPage implements OnInit
{
    //optionsMulti: CalendarComponentOptions = {
    //    pickMode: 'range'
    //};
    options: CalendarComponentOptions;
    dateRange: any;
    type: string;
    

    constructor(private navParams: NavParams, private modalCtrl: ModalController) {
        this.options = navParams.get('options');
        this.dateRange = navParams.get('dateRange');
    }

    ngOnInit() {

    }

    ok() {
        this.modalCtrl.dismiss(this.dateRange);
    }

    cancel() {
        this.modalCtrl.dismiss();
    }
}
