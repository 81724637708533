import { Component } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Pages } from './interfaces/pages';
import { StorageService } from './providers/storage.service';
import { GlobalService } from './providers/global.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent
{
    gs = GlobalService;

    public appPages: Array<Pages>;
    public userData = {};
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        public navCtrl: NavController
    )
    {
        this.appPages = [     
            {
                title: 'Search Stock',
                url: '/search-stock',
                direct: 'root',
                icon: 'search'
            },
            {
                title: 'Dashboard',
                url: '/home-results',
                direct: 'root',
                icon: 'stats'
            },
            {
                title: 'Order',
                url: '/order-list',
                direct: 'root',
                icon: 'list'
            },
            {
                title: 'Invoice',
                url: '/invoice-list',
                direct: 'root',
                icon: 'list'
            },
            {
                title: 'Customer Ledger',
                url: '/customer-ledger',
                direct: 'root',
                icon: 'list'
            },
            {
                title: 'Discontinued Items',
                url: '/remove_shopping_cart/discontinued',
                direct: 'root',
                icon: 'list'
            },
            {
                title: 'Customers',
                url: '/customers',
                direct: 'forward',
                icon: 'list'
            },
            {
                title: 'Enquiries',
                url: '/enquiries',
                direct: 'forward',
                icon: 'list'
            },
            //{
            //    title: 'Complaints',
            //    url: '/complaints',
            //    direct: 'forward',
            //    icon: 'clipboard'
            //},
            {
                title: 'New Enquiry',
                url: '/new_enquiries/0/""',
                direct: 'forward',
                icon: 'person-add'
            },
            //{
            //    title: 'New Complaint',
            //    url: '/new_complaints',
            //    direct: 'forward',
            //    icon: 'person-add'
            //},
            {
                title: 'Task Detail Report',
                url: '/task_detail_report/0/0',
                direct: 'root',
                icon: 'clipboard'
            },
            {
                title: 'Task Summary Report',
                url: '/task_summary_report/0/0',
                direct: 'root',
                icon: 'clipboard'
            },
            {
                title: 'New Lead',
                url: '/lead/0',
                direct: 'root',
                icon: 'clipboard'
            },
            {
                title: 'Leads',
                url: '/leads',
                direct: 'root',
                icon: 'clipboard'
            },
            //{
            //    title: 'Work Delay Report',
            //    url: '/home-results',
            //    direct: 'root',
            //    icon: 'clipboard'
            //},
            //{
            //    title: 'Enquiry Reschedule Report',
            //    url: '/home-results',
            //    direct: 'root',
            //    icon: 'clipboard'
            //},
            //{
            //    title: 'Kra Report',
            //    url: '/home-results',
            //    direct: 'root',
            //    icon: 'clipboard'
            //}
            //{
            //    title: 'Home',
            //    url: '/home-results',
            //    direct: 'root',
            //    icon: 'home'
            //},
            //{
            //    title: 'About',
            //    url: '/about',
            //    direct: 'forward',
            //    icon: 'information-circle-outline'
            //},

            //{
            //    title: 'App Settings',
            //    url: '/settings',
            //    direct: 'forward',
            //    icon: 'cog'
            //}
        ];

        this.initializeApp();
    }

    initializeApp()
    {
        this.platform.ready().then(() =>
        {
            this.userData = StorageService.get('user');
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        }).catch(() => { });
    }

    goToEditProfile()
    {
        this.navCtrl.navigateForward('edit-profile');
    }

    logout()
    {
        this.navCtrl.navigateRoot('/');
    }
}
