import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { TaskDetailsPage } from './task-details.page';

const routes: Routes = [
  {
    path: '',
        component: TaskDetailsPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
      IonicModule,
      NgxDatatableModule,
    RouterModule.forChild(routes)
  ],
    declarations: [TaskDetailsPage]
})
export class TaskDetailsPageModule {}
