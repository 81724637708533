import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { IonicService } from './ionic.service';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable()
export class UploadService
{
    gs = GenericService;
    extensionLists = { image: ['jpg', 'gif', 'bmp', 'png'], audio: ['m4a', 'mp3', 'wav'], video: ['m4v', 'avi', 'mpg', 'mp4', 'webm'] };
    //SERVER_URL: string = "http://localhost:18292/project-files/upload-attachment";
    constructor(private ions: IonicService, private httpClient: HttpClient)
    {
    }

    public upload(formData, type,recid)
    {
        let SERVER_URL = environment.baseUrl + "api/measurement/upload-attachment?recid=" + recid;         
        return this.httpClient.post<any>(SERVER_URL, formData, {
            reportProgress: true,
            observe: 'events'
        });
    }
}

