import { Injectable, Renderer } from '@angular/core';
import { ChartService } from './chart.service';
//import { Angular2Csv } from 'angular2-csv/Angular2-csv';

declare var $: any;
declare var FusionCharts: any;

@Injectable()
export class FusionChartsService
{
  constructor(private chartService: ChartService)
  {

  }

  //public getChart(chartConfig, renderer: Renderer = null)
  //{
  //  this.chartService.getTable(chartConfig)
  //    .subscribe(
  //    (data) =>
  //    {
  //      this.renderChartByID(chartConfig.type, data, chartConfig.id, chartConfig.width, chartConfig.height, renderer);
  //    },
  //    (err) =>
  //    {
  //      console.log(err);
  //    });
  //}

  public static renderChartById(chartType, dataSource, id, width = "100%", height = "250px") {
      return FusionCharts.ready(function () {
          if (document.getElementById(id)) {
              var chart = new FusionCharts({
                  type: chartType,
                  renderAt: id,
                  width: width,
                  height: height,
                  containerBackgroundOpacity: 0,
                  dataFormat: 'json',
                  dataSource: dataSource
                  //'events': {
                  //    'dataplotClick': function (eventObj, dataObj) {
                  //        //dataObj["categoryLabel"] = eventObj.sender.args.renderAt;
                  //        FusionChartsService._clickObserver.next(dataObj);
                  //    }
                  //}
              });

              chart.render();
          }
      });
  }


  public static renderChartByID(chartType, data, id, width = "100%", height = undefined, renderer: Renderer)
  {
    FusionCharts.ready(function ()
    {
      var visitChart = new FusionCharts({
        type: chartType,
        renderAt: id,
        width: width,
        height: height,
        dataFormat: 'json',
        dataSource: data

      }).render();
      setTimeout(function ()
      {
        var element = $("#" + id);
        var item2 = $('<div class="' + chartType + '" id="hdnexport' + id + '" style="display:none">' + JSON.stringify(data) + '</div>');
        element.append(item2);
        if (renderer == null)
          return;
        element.bind("click", function ()
        {
          var contextMenu = $(element).find('.fusioncharts-container div:first div:first');
          var opt = contextMenu.find('.excel-export');
          if (opt.length == 0)
          {
            var item = $('<div id="export' + id + '" class="excel-export" style="height: 18px; line-height: 12px; text-align: left; padding-left: 5px; padding-right: 5px; padding-top: 5px; cursor: pointer; border-width: 0px; font-family: Arial; font-size: 10px; color: rgb(51, 51, 51); background-color: rgb(255, 255, 255);">Export as CSV</div>');
            contextMenu.append(item);

            renderer.listen(item[0], 'click', (event) =>
            {

              var ExcelHeaders = [];
              var chartData = { Categories: [], DataSet: [], Data: [] };

              var id = "hdn" + event.srcElement.id;
              var component = $("#" + id);
              var className = $(component).attr("class");
              //if (chartType.startsWith('ms'))
              chartData = JSON.parse($(component).html());
              var ResultData = [];

              if (!chartType.startsWith('ms') && !chartType.startsWith('stacked'))
              {
                $.each(chartData.Data, function (i, item)
                {
                  var data = { Label: item.Label, Value: item.Value };
                  ResultData.push(data);
                });
              }
              else
              {
                var obj = ['Series'];
                $.each(chartData.Categories[0].Category, function (i, item)
                {
                  obj.push(item.Label);
                });
                ResultData.push(obj);

                if (chartData.DataSet[0].SeriesName == "1")
                {
                  var ser1Data = chartData.DataSet.filter(x => x.SeriesName == "1");
                  var ser2Data = chartData.DataSet.filter(x => x.SeriesName == "2");
                  var ser3Data = chartData.DataSet.filter(x => x.SeriesName == "3");

                  var data = { Label: "1" };

                  $.each(chartData.Categories[0].Category, function (j, subItem)
                  {
                    data[subItem.Label] = (ser1Data[j].Data.length - 1 >= j ? ser1Data[j].Data[j].Value : "0") + "%";
                  });

                  ResultData.push(data);

                  if (ser2Data && ser2Data.length > 0)
                  {
                    var data = { Label: "2" };
                    $.each(chartData.Categories[0].Category, function (j, subItem)
                    {
                      if (subItem.Label == chartData.Categories[0].Category[chartData.Categories[0].Category.length - 2].Label)
                        data[subItem.Label] = ser2Data[0].Data[ser2Data[0].Data.length - 1].Value + "%";
                      else
                        data[subItem.Label] = "0%";
                    });
                    ResultData.push(data);
                  }
                  if (ser3Data && ser3Data.length > 0)
                  {
                    var data = { Label: "3" };
                    $.each(chartData.Categories[0].Category, function (j, subItem)
                    {
                      if (subItem.Label == chartData.Categories[0].Category[chartData.Categories[0].Category.length - 2].Label)
                        data[subItem.Label] = ser3Data[0].Data[ser3Data[0].Data.length - 1].Value + "%";
                      else
                        data[subItem.Label] = "0%";
                    });
                    ResultData.push(data);
                  }

                }
                else
                {
                  $.each(chartData.DataSet, function (i, item)
                  {
                    var data = { Label: item.SeriesName };
                    $.each(chartData.Categories[0].Category, function (j, subItem)
                    {
                      data[subItem.Label] = (item.Data.length - 1 >= j ? item.Data[j].Value : "0") + "%";
                    });
                    ResultData.push(data);
                  });
                }
                

                //ResultData.push(data);
                //ResultData.push(data);
                //ResultData.push(data);
                //ResultData.push(data);

                //data = {};
                //data.Label = "Base";
                //ResultData.push(data);
                //$.each(chartData.Categories[0].Category, function (i, item)
                //{
                //    data[item.Label] = " " + item.Label;
                //});

                //$.each(chartData.DataSet, function (i, item)
                //{
                //    data = {};
                //    data.Label = item.SeriesName;
                //    $.each(chartData.Categories[0].Category, function (j, subItem)
                //    {
                //        data[subItem.Label] = item.Data[j].Base;
                //    });
                //    ResultData.push(data);
                //});
              }

              var options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalseparator: '.',
                showLabels: true,
                showTitle: false
              };
              //new Angular2Csv(ResultData, 'Data', options);
            });
          }
        });

        //$("tspan").each(function () {
        //    if ($(this)[0].textContent == "FusionCharts XT Trial") {
        //        $(this).hide();
        //    }
        //});

      }, 700);
    });
  }

  public static setChartHeight(chart, context)
  {
    var ratio = $(window).width() / $(window).height();
    //var ch = context.charts.filter(x => x.ID == chart.ID)[0];
    if (ratio > 1)
      chart.Height = $("#" + chart.ID).width() / ratio;
    else
      chart.Height = $("#" + chart.ID).width() / ratio;
  }
}
