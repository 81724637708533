import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';

declare var Chart: any;

@Injectable()
export class ChartjsService
{
    static options = {
        responsive: true,
        responsiveAnimationDuration: 500,
        maintainAspectRatio: false,
        legend: {
            position:'bottom'
        },
        scales: {
            yAxes: [{
                ticks:
                {
                    beginAtZero: true,
                    steps: 10,
                    max:100
                }
            }]
        },
        tooltipTemplate: "<%= value %>",

        showTooltips: true,

        onAnimationComplete: function ()
        {
            this.showTooltip(this.datasets[0].bars, true);
        },
        tooltipEvents: []
    }
    static renderChart(table, config)
    {
        let chartData = { labels: table.Stubs, datasets: [] };
        let i = 0;
        table.Banners.forEach(banner =>
        {
            let color = this.colors.length > i ? this.colors[i] : GenericService.getRandomColorHex();
            let ds: any = {
                label: banner.Name,
                data: banner.Values,
                fill: false,
                borderColor: color,
                backgroundColor: color + "80",
                hidden:banner.hidden
            };
            chartData.datasets.push(ds);
            i++;
        });

        let canvas = <HTMLCanvasElement>document.getElementById(config.id);
        let ctx = canvas.getContext("2d");

        new Chart(ctx, {
            type: config.type,
            data: chartData,
            options: this.options
        });
    }

    static colors = [
        '#3366CC',
        '#DC3912',
        '#FF9900',
        '#109618',
        '#990099',
        '#3B3EAC',
        '#0099C6',
        '#DD4477',
        '#66AA00',
        '#B82E2E',
        '#316395',
        '#994499',
        '#22AA99',
        '#AAAA11',
        '#6633CC',
        '#E67300',
        '#8B0707',
        '#329262',
        '#5574A6',
        '#3B3EAC',
        "#3e95cd",
        "#8e5ea2",
        "#3cba9f",
        "#e8c3b9",
        "#c45850",
    ]
}
