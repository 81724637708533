﻿import {Http, Headers, Response} from '@angular/http';
import { JwtHelper, tokenNotExpired } from 'angular2-jwt';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { Constants } from '../providers/constants';
import { StorageService } from '../providers/storage.service';
import { MasterService } from '../providers/master.service';
import { throwError } from 'rxjs';
import { filter, map, catchError } from 'rxjs/operators';
import { GlobalService } from '../providers/global.service';

@Injectable()
export class AuthService
{
    gs = GlobalService;
    jwtHelper: JwtHelper = new JwtHelper();
    public static user: any = {};
    constructor(private masterService: MasterService)
    {

    }

    public isLoggedin()
    {
        // Check if there's an unexpired JWT
        return tokenNotExpired();
    }

    getUser(): any
    {
        return StorageService.get('user');
    }

    public getToken(): any {
        var user = this.getUser();
        if (user == null)
            return "";
        return user.Token;
    }

    setUser(data): any
    {
        this.masterService.setUser(data);
    }

    login(userName: string, password: string): Observable<boolean>
    {
        let data = "grant_type=password&userName=" + userName + "&password=" + password + '&client_id=' + Constants.clientID;

        return this.masterService.postData('token', data, true)
            .map((response: Response) =>
            {
                var user = this.setUser(response);
                this.gs.User = response;
                return user;
            });
    }

    refreshToken(refreshToken: any): Observable<any>
    {
        var data = "grant_type=refresh_token&refresh_token=" + refreshToken + '&client_id=' + Constants.clientID;

        return this.masterService.postData('token', data, true)
            .map((response: Response) =>
            {
                var user = this.setUser(response);
                return user;
            });
    }

    logout(): void
    {
        StorageService.remove('user');
    }
    public static hasRole(roleCode, user = null): boolean {
        user = user || AuthService.user;
        if (!user || !user.Roles)
            return false
        else {
            return user.Roles.filter(x => x.Code == roleCode).length > 0;
        }
    }
    hasRole(roleName, user): boolean
    {
        if (!user || !user.Roles)
            return false
        else
        {
            return user.Roles.filter(x => x.Name == roleName).length > 0;
        }
    }
}