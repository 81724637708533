import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import { JwtHelper, AuthHttp } from 'angular2-jwt';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { Constants } from './constants';
import { environment } from '../../environments/environment';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';


@Injectable()
export class MasterService
{
    jwtHelper: JwtHelper = new JwtHelper();
    constructor(private http: HttpClient, private authHttp: AuthHttp)
    {

    }

    setUser(data): any
    {
        var user =
            {
                UserName: data.userName,
                Name: data.Name,
                Role: data.Role,
                ERPCode: data.ERPCode,
                Token: data.access_token
            }
        //let user = this.jwtHelper.decodeToken(data.access_token);
        //user.access_token = data.access_token;
        //user.refresh_token = data.refresh_token;
        //user.DefaultLocation = user.DefaultLocation ? JSON.parse(user.DefaultLocation) : null;
        //user.Locations = user.Locations ? JSON.parse(user.Locations) : [];
        //user.Roles = user.Roles ? JSON.parse(user.Roles) : [];
        //user.Permissions = [];

        //for (var i = 0; i < user.Roles.length; i++)
        //{
        //    for (var j = 0; j < user.Roles[i].Permissions.length; j++)
        //    {
        //        user.Permissions.push(user.Roles[i].Permissions[j]);
        //    }
        //}
        StorageService.set('user', user);
        return user;
    }

    callService1(serviceUrl: string, skipAuth: boolean = false, method = 'Get', data = null): Observable<any>
    {
        const requestOptions = {
            params: new HttpParams()
        };

        //var http = skipAuth ? this.http : this.authHttp;
        var http = this.http;
        if (method == 'Get')
        {
            return http.get(environment.baseUrl + serviceUrl)
                .map((response: Response) =>
                {
                    //var res = response.json();
                    return response;
                }).catch(this.handleError);
        }
        else
        {
            return http.post(environment.baseUrl + serviceUrl, data)
                .map((response: Response) =>
                {
                    //var res = response.json();
                    return response;
                }).catch(this.handleError);
        }
    }

    callService(serviceUrl: string, skipAuth: boolean = false, method = 'Get', data = null): Observable<any>
    {
        //var user = StorageService.get('user');
        //var currentDate = new Date();
        //if (!skipAuth && !user)
        //{
        //    return Observable.throw("Login Required");
        //}
        //else if (!skipAuth && user && this.jwtHelper.isTokenExpired(user.access_token) && user.refresh_token)
        //{
        //    var d = "grant_type=refresh_token&refresh_token=" + user.refresh_token + '&client_id=' + Constants.clientID;

        //    let res = this.http.post(environment.baseUrl + 'token', d)
        //        .map((response: Response) =>
        //        {
        //            var res = response.json();
        //            var user = this.setUser(res);
        //            return res;
        //        })
        //        .mergeMap(res => this.callService1(serviceUrl, skipAuth, method, data));

        //    return res;
        //}
        //else
        //{
            return this.callService1(serviceUrl, skipAuth, method, data);
        //}
    }

    callGetService(serviceUrl: string, skipAuth: boolean = false): Observable<any>
    {
        return this.callService(serviceUrl, skipAuth, 'Get');
    }

    callPostService1(serviceUrl: string, skipAuth: boolean = false,data): Observable<any> {
        return this.callService(serviceUrl, skipAuth, 'Post', data);
    }

    callPostService(serviceUrl: string, skipAuth: boolean = false): Observable<any>
    {
        return this.callService(serviceUrl, skipAuth, 'Post');
    }

    getList(serviceName: string = null, pageNo: number = null, pageSize: number = null, orderBy: string = null, filter: string = null): Observable<any>
    {
        pageNo = pageNo || 1;
        pageSize = pageSize || 50;
        filter = encodeURIComponent(GenericService.nullToEmpty(filter));
        var url = serviceName + '/list?';
        url += "pageNo=" + pageNo + "&pageSize=" + pageSize + "&orderBy=" + GenericService.nullToEmpty(orderBy) + "&filters=" + filter;

        return this.callGetService(url, true);
    }

    getPostList(serviceName: string, filter: Object, pageNo: number = null, pageSize: number = null, orderBy: string = null): Observable<any> {
        pageNo = pageNo || 1;
        pageSize = pageSize || 50;
        var url = serviceName;
        //filter["PageNo"] = pageNo;
        //filter["PageSize"] = pageSize;
        //url += "pageNo=" + pageNo + "&pageSize=" + pageSize + "&orderBy=" + GenericService.nullToEmpty(orderBy);
        return this.callService(url, true, "Post", filter);
    }

    getData(serviceName: string = null, pageNo: number = null, pageSize: number = null, orderBy: string = null, filter: string = null): Observable<any>
    {
        pageNo = pageNo || 1;
        pageSize = pageSize || 50;
        filter = encodeURIComponent(GenericService.nullToEmpty(filter));
        var url = serviceName + (serviceName.indexOf("?") > -1 ? "&" : '?');
        url += "pageNo=" + pageNo + "&pageSize=" + pageSize + "&orderBy=" + GenericService.nullToEmpty(orderBy) + "&filters=" + filter;

        return this.callGetService(url, true);
    }

    getDetails(serviceName: string, id: any): Observable<any>
    {
        return this.callGetService(serviceName + '/details?id=' + id);
    }

    fetchData(serviceName: string, filter: string = null): Observable<any>
    {
        filter = encodeURIComponent(GenericService.nullToEmpty(filter));
        return this.callGetService(serviceName + "?filter=" + filter);
    }

    bindDropDown(masterName, filterStr = null, locationID = 0, pageNo = 0, pageSize = 0)
    {
        filterStr = filterStr ? "Name:" + filterStr : '';
        var serviceName = masterName;
        if (masterName == 'SalesPerson')
        {
            filterStr = filterStr ? filterStr + "&" : '';
            filterStr += "RoleName:Sales Person;Locations:" + locationID;
            serviceName = "user";
        }
        if (masterName == 'Architect')
        {
            filterStr = filterStr ? filterStr + "&" : '';
            filterStr += "RoleName:Architect;Locations:" + locationID;
            serviceName = "user";
        }
        if (masterName == 'Worker')
        {
            filterStr = filterStr ? filterStr + "&" : '';
            filterStr += "RoleName:Worker;Location:" + locationID;
            serviceName = "user";
        }
        return this.getList(serviceName, 0, 0, null, filterStr);
    }

    postData(serviceUrl: string, data: any, skipAuth: boolean = false): Observable<any>
    {
        return this.callService(serviceUrl, skipAuth, 'Post', data);
    }

    save(serviceUrl: string, data: any): Observable<any>
    {
        return this.postData(serviceUrl + '/save', data);
    }

    public handleError(error: Response | any)
    {

        var errMsg = fetchErrorMessage(error);
        console.log(errMsg);
        return Observable.throw(errMsg);
    }

    public fetchErrorMessage(error: Response | any)
    {
        return fetchErrorMessage(error);
    }
}

function fetchErrorMessage(error: Response | any)
{
    let errMsg: string;
    if (error instanceof Response)
    {
        if (error.status == 0)
        {
            errMsg = "Could not connect to server";
        }
        else
        {
            errMsg = error.text();
            if (errMsg.indexOf("{") == 0)
                errMsg = error.json().error_description;
        }
    }
    else if (typeof error === 'string')
    {
        errMsg = error;
    }
    else
    {
        errMsg = error.message || error.body || error.toString();
    }
    return errMsg;
}
