import { Component } from '@angular/core';
import { finalize } from "rxjs/internal/operators/finalize";
import { debounceTime } from "rxjs/operators";
import { Observable } from 'rxjs/Rx'
import { MasterService } from '../../../providers/master.service';
import { GlobalService } from '../../../providers/global.service';
import { GenericService } from '../../../providers/generic.service';
import { FusionChartsService } from "../../../providers/fusion-charts.service";
import {  IonicService } from "../../../providers/ionic.service";
import { ActivatedRoute, Router } from '@angular/router';
import
{
    NavController,
    AlertController,
    MenuController,
    ToastController,
    PopoverController,
    ModalController,
    NavParams
} from '@ionic/angular';

import { ImagePage } from '../image/image.page';
// Call notifications test by Popover and Custom Component.
import { NotificationsComponent } from '../../../components/notifications/notifications.component';

@Component({
    selector: 'app-task-details',
    templateUrl: './task-details.page.html',
    styleUrls: ['./task-details.page.scss']
})
export class TaskDetailsPage
{
    columnName: any;
    rowName: any;
    filters: any;
    dataFetcherEvent: any;
    spinner: boolean;
    pageSize = 10;
    pageNo = 1;
    list: any = [];
    listColumns: any = {};
    selected: any;
    constructor(
        public navCtrl: NavController,
        public menuCtrl: MenuController,
        public popoverCtrl: PopoverController,
        public alertCtrl: AlertController,
        public modalCtrl: ModalController,
        public toastCtrl: ToastController,
        private masterService: MasterService, public ionicService: IonicService,
        private router: Router, private route: ActivatedRoute,
        private navParams: NavParams
    )
    {
        this.filters = navParams.get('filters');
        if (this.filters)
            this.getList(event, this.filters.TaskID);
        //this.columnName = navParams.get('columnName');
        //this.rowName = navParams.get('rowName');
    }
    
    getList(event,table)
    {
        let mode = null;
        this.ionicService.hideLoader();
        if (!mode || mode == 'search')
            this.spinner = true;

        var pageNo = this.pageNo;
        if (mode == "refresh" && this.pageNo > 0)
        {

            pageNo--;
        }
        if (mode == "infiniteScroll")
        {
            pageNo++;
        }

        this.dataFetcherEvent = event;
        var that = this;
        this.ionicService.showLoader();


        if (table == 1)
        {
            this.masterService.callService1('api/report/taskDetails', true, 'Post', this.filters).pipe(debounceTime(500),
                finalize(() =>
                {

                }))
                .subscribe(list =>
                {
                    that.ionicService.hideLoader();
                    if (mode == "infiniteScroll")
                        event.target.complete();

                    if (mode == "infiniteScroll" && list.length == 0)
                    {
                        return;
                    }

                    this.pageNo = pageNo;
                    if (mode != "infiniteScroll")
                    {
                        that.list.splice(0); //clear
                    }
                    that.list = that.list.concat(list);
                    if (that.list.length > 0)
                        that.listColumns = Object.keys(that.list[0]);
                },
                err =>
                {
                });
        }
        if (table == 2)
        {
            this.masterService.callService1('api/report/measurerWorkLoadDetails', true, 'Post', this.filters).pipe(debounceTime(500),
                finalize(() =>
                {

                }))
                .subscribe(list =>
                {
                    that.ionicService.hideLoader();
                    if (mode == "infiniteScroll")
                        event.target.complete();

                    if (mode == "infiniteScroll" && list.length == 0)
                    {
                        return;
                    }

                    this.pageNo = pageNo;
                    if (mode != "infiniteScroll")
                    {
                        that.list.splice(0); //clear
                    }
                    that.list = that.list.concat(list);
                    if (that.list.length > 0)
                        that.listColumns = Object.keys(that.list[0]);
                },
                    err =>
                    {
                    });
        }
        if (table == 3)
        {
            this.masterService.callService1('api/report/icCategoryWiseTaskDetails', true, 'Post', this.filters).pipe(debounceTime(500),
                finalize(() =>
                {

                }))
                .subscribe(list =>
                {
                    that.ionicService.hideLoader();
                    if (mode == "infiniteScroll")
                        event.target.complete();

                    if (mode == "infiniteScroll" && list.length == 0)
                    {
                        return;
                    }

                    this.pageNo = pageNo;
                    if (mode != "infiniteScroll")
                    {
                        that.list.splice(0); //clear
                    }
                    that.list = that.list.concat(list);
                    if (that.list.length > 0)
                        that.listColumns = Object.keys(that.list[0]);
                },
                    err =>
                    {
                    });
        }
        if (table == 4)
        {
            this.masterService.callService1('api/report/categoryWiseTaskDetails', true, 'Post', this.filters).pipe(debounceTime(500),
                finalize(() =>
                {

                }))
                .subscribe(list =>
                {
                    that.ionicService.hideLoader();
                    if (mode == "infiniteScroll")
                        event.target.complete();

                    if (mode == "infiniteScroll" && list.length == 0)
                    {
                        return;
                    }

                    this.pageNo = pageNo;
                    if (mode != "infiniteScroll")
                    {
                        that.list.splice(0); //clear
                    }
                    that.list = that.list.concat(list);
                    if (that.list.length > 0)
                        that.listColumns = Object.keys(that.list[0]);
                },
                    err =>
                    {
                    });
        }
    }

    ionViewWillEnter()
    {
        this.menuCtrl.enable(true);
    }

    async closeModal()
    {
        await this.modalCtrl.dismiss();
    }
}
