﻿import { Injectable } from '@angular/core';
import { MasterService } from './master.service';

@Injectable()
export class ChartService
{
    constructor(private masterService:MasterService)
    {

    }

    public getTable(chartConfig)
    {
        var url = chartConfig.service;
        var qs = "";
        if (chartConfig.frequency)
            qs += "&frequency=" + chartConfig.frequency;
        if (chartConfig.brands)
            qs += "&brands=" + chartConfig.brands;
        if (chartConfig.nvGroup)
            qs += "&nvGroup=" + chartConfig.nvGroup;
        if (chartConfig.variable)
            qs += "&variable=" + chartConfig.variable;
        if (chartConfig.Table)
            qs += "&table=" + chartConfig.table;
        if (chartConfig.isPercentage)
            qs += "&isPercentage=" + chartConfig.isPercentage;
        if (chartConfig.args)
            qs += "&args=" + chartConfig.args;
        if (chartConfig.filters)
            qs += "&filters=" + chartConfig.filters;

        if (qs.length > 0)
            url += '?' + qs;

        return this.masterService.callGetService(url, true);
    }

    tableToDataTable(table)
    {
        let dt = [];

        table.Stubs.forEach((stub, i) =>
        {
            let row = { '': stub.Name };
            table.Banners.forEach(banner =>
            {
                row[banner.Name] = banner.Values[i];
            });
            dt.push(row);
        });

        return dt;
    }

    exportToCsv(data)
    {
        var options =
            {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalseparator: '.',
                showLabels: true,
                showTitle: false,
                useBom: true,
                headers: Object.keys(data[0])
            };

       // new Angular2Csv(data, 'csv', options);
    }
}