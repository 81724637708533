﻿import { Injectable } from '@angular/core';
import * as moment from 'moment';

declare var $: any;

@Injectable()
export class GenericService
{
    public static serializeDate(date): string
    {
        return moment(date).format("YYYY-MM-DD");
    }
    public static serializeDateToDDMMYYYY(date): string {
        return moment(date).format("DD-MM-YYYY");
    }
    public static serializeDateToDDMMMYYYY(date): string {
        return moment(date).format("DD-MMM-YYYY");
    }
    public static nullToEmpty(value): string
    {
        value = value ? value : '';
        return value;
    }

    public static presentToast(toastCtrl, text, position = 'bottom'): void {
        var toast = toastCtrl.create({
            message: text,
            duration: 3000,
            position: position
        });
        toast.present();
    }

    public static arrayToMap(array, keyProp = 'name') {
        let obj: any = {};
        if (!array)
            return obj;
        array.forEach(x => {
            obj[x[keyProp]] = x;
        });

        return obj;
    }

    public static isEmpty(value: any): boolean
    {
        // we don't check for string here so it also works with arrays
        return value == null || value.length === 0;
    }

    public static fetchErrorMsg(error)
    {
        if (error == null)
            return "";

        if (typeof error === 'object')
        {
            try
            {
                error = error.json();
            }
            catch (err)
            {
            }

            if (error.message)
                return error.message;

            if (error instanceof ProgressEvent)
            {
                return "Could not connect to server.";
            }

            error = error.error_description || error.Message || error._body || error;

            return JSON.stringify(error);
        }
        return error;
    }
    public static scrollTop()
    {
        var alertBar = $('alert-bar');
        if (alertBar.length)
        {
            $('html, body').animate({
                scrollTop: (alertBar.offset().top)
            }, 500);
        }
    }

    public static datePickerOptions =
    {
        singleDatePicker: true,
        showDropdowns: true,
        opens: "left",
    }

    public static getColorByStatus(status) {
        if (status == 'Open')
            return 'secondary';
        if (status == 'Canceled')
            return 'danger';
        if (status == 'Closed')
            return 'success';
        if (status == 'Overdue')
            return 'danger';
    }

    public static getPropValue(obj, propPath)
    {
        var arr = propPath.split('.');
        while (arr.length && (obj = obj[arr.shift()]));
        return obj;
    }
    public static setPropValue(object, path, value)
    {
        var a = path.split('.');
        var o = object;
        for (var i = 0; i < a.length - 1; i++)
        {
            var n = a[i];
            if (n in o)
            {
                o = o[n];
            } else
            {
                o[n] = {};
                o = o[n];
            }
        }
        o[a[a.length - 1]] = value;
    }

    public static compareById(obj1: any, obj2: any): boolean {
        return obj1 && obj2 ? obj1.Id === obj2.Id : obj1 === obj2;
    }
    public static ddlSettings: any =
    {
        selectionLimit: 1,
        autoUnselect: true,
        closeOnSelect: true,
        maxHeight: '300px',
        checkedStyle: 'fontawesome',
        buttonClasses: 'btn btn-default btn-block',
        displayAllSelectedText: false,
        dynamicTitleMaxItems: 1,
    };

    public static ddlSettingsWithSearch: any =
    {
        selectionLimit: 1,
        autoUnselect: true,
        closeOnSelect: true,
        maxHeight: '300px',
        checkedStyle: 'fontawesome',
        buttonClasses: 'btn btn-default btn-block',
        displayAllSelectedText: false,
        dynamicTitleMaxItems: 1,
        enableSearch: true
    };

    public static ddlSettingsMultipleWithSearch: any =
    {
        maxHeight: '300px',
        checkedStyle: 'fontawesome',
        buttonClasses: 'btn btn-default btn-block',
        displayAllSelectedText: true,
        dynamicTitleMaxItems: 2,
        enableSearch: true
    };

    public static validateForm():string
    {
        var form = $("form");
        if (form.hasClass("ng-invalid") && $('.ng-invalid').length>1)
        {
            $('.ng-invalid').addClass('ng-dirty');
            //$('.ng-invalid').focus();
            var msg = 'Validation failed.';
            return msg;
        }
        return null;
    }

    public static toCSV(list, propName=null, seperator = ",")
    {
        if (!list)
            return "";
        var str = "";
        for (let item of list)
        {
            str += seperator + " " + (propName ? item[propName] : item);
        }
        if (str.length > 0)
            return str.substring(2, str.length);

        return str;
    }

    public static getRandomColorHex()
    {
        return'#' + Math.random().toString(16).slice(-6);
    }

    public static clone(obj)
    {
        return JSON.parse(JSON.stringify(obj));
    }

    public static addToQueryString(url, key, value)
    {
        let qs = "";
        if (url.indexOf('?') == -1)
            qs += "?";
        qs += key + "=" + value;
        return url + qs;
    }

    public static getSelectedValue(brands)
    {
        var selectedBrands = brands.filter(x => x.Selected);
        if (selectedBrands.length == 0 && brands.length > 0)
            selectedBrands = [brands[0]];
        return selectedBrands;
    }
}
