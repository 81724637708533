﻿//import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
//import { Injectable } from '@angular/core';
//import { Observable } from 'rxjs/Observable';

//import { AuthService } from './auth.service';

//@Injectable()
//export class AuthInterceptor implements HttpInterceptor {
//    constructor(public auth: AuthService) { }
//    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//        req = req.clone({
//            setHeaders: {
//                'Content-Type': 'application/json; charset=utf-8',
//                'Accept': 'application/json',
//                'Authorization': `Bearer ${this.auth.getToken()}`,
//            },
//        });

//        return next.handle(req);
//    }
//}

import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs/Observable';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        request = request.clone({
            setHeaders: {
                Authorization: 'Bearer ' + this.auth.getToken()
            }
        });
        return next.handle(request);
    }
}