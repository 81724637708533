import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    { path: '', loadChildren: './pages/login/login.module#LoginPageModule' },
    { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
    { path: 'register', loadChildren: './pages/register/register.module#RegisterPageModule' },
    { path: 'search-stock', loadChildren: './pages/item-list/item-list.module#ItemListPageModule' },
    { path: 'item-card/:code', loadChildren: './pages/item-card/item-card.module#ItemCardPageModule' },
    { path: 'remove_shopping_cart/:mode', loadChildren: './pages/item-list/item-list.module#ItemListPageModule' },
    { path: 'order-list', loadChildren: './pages/order-list/order-list.module#OrderListPageModule' },
    { path: 'order-card/:code', loadChildren: './pages/order-card/order-card.module#OrderCardPageModule' },
    { path: 'invoice-list', loadChildren: './pages/invoice-list/invoice-list.module#InvoiceListPageModule' },
    { path: 'invoice-card/:code', loadChildren: './pages/invoice-card/invoice-card.module#InvoiceCardPageModule' },
    { path: 'customer-ledger', loadChildren: './pages/customer-ledger/customer-ledger.module#CustomerLedgerPageModule' },
    { path: 'about', loadChildren: './pages/about/about.module#AboutPageModule' },
    { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule' },
    { path: 'edit-profile', loadChildren: './pages/edit-profile/edit-profile.module#EditProfilePageModule' },
    { path: 'home-results', loadChildren: './pages/home-results/home-results.module#HomeResultsPageModule' },
    { path: 'enquiries', loadChildren: './pages/enquiries/enquiries.module#EnquiriesPageModule' },
    { path: 'customers', loadChildren: './pages/customers/customers.module#CustomersPageModule' },
    { path: 'complaints', loadChildren: './pages/complaints/complaints.module#ComplaintsPageModule' },
    { path: 'new_enquiries/:id/:custcode', loadChildren: './pages/new_enquiries/new_enquiries.module#New_EnquiriesPageModule' },
    { path: 'new_complaints', loadChildren: './pages/new_complaints/new_complaints.module#New_ComplaintsPageModule' },
    { path: 'task_detail_report/:TaskType/:TaskStatus', loadChildren: './pages/task_detail_report/task_detail_report.module#Task_Detail_ReportPageModule' },
    { path: 'task_summary_report/:TaskType/:TaskStatus', loadChildren: './pages/task_summary_report/task_summary_report.module#Task_Summary_ReportPageModule' },
    //{ path: 'lead/:LeadId', loadChildren: './pages/lead/lead.module#LeadPageModule' },
    { path: 'enquiry-summary/:id/:selectedTab', loadChildren: './pages/enquiry-summary/enquiry-summary.module#EnquirySummaryPageModule' },
    { path: 'task-card/:enquiryID/:taskID', loadChildren: './pages/task-card/task-card.module#TaskCardPageModule' },
    
    //{ path: 'leads', loadChildren: './pages/lead-list/lead-list.module#LeadListPageModule' },
    {
        path: 'measurement-listing/:enquiryID/:type',
        loadChildren: './pages/measurement-listing/measurement-listing.module#MeasurementListingPageModule'
    },
    {
        path: 'measurement-card/:enquiryID/:category/:recid',
        loadChildren: './pages/measurement-card/measurement-card.module#MeasurementCardPageModule'
    },
    {
        path: 'hardware-measurement-card/:enquiryID/:category/:recid',
        loadChildren: './pages/hardware-measurement-card/hardware-measurement-card.module#HardwareMeasurementCardPageModule'
    },
    {
        path: 'sofa-measurement-card/:enquiryID/:category/:recid',
        loadChildren: './pages/sofa-measurement-card/sofa-measurement-card.module#SofaMeasurementCardPageModule'
    },
    {
        path: 'blind-measurement-card/:enquiryID/:category/:recid',
        loadChildren: './pages/blind-measurement-card/blind-measurement-card.module#BlindMeasurementCardPageModule'
    },
    {
        path: 'cc-measurement-card/:enquiryID/:category/:recid',
        loadChildren: './pages/cc-measurement-card-new/cc-measurement-card-new.module#CCMeasurementCardNewPageModule'
    },
    {
        path: 'mattress-card/:enquiryID/:category/:recid',
        loadChildren: './pages/mattress-card/mattress-card.module#MattressCardPageModule'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    //imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule { }
