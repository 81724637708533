import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { CalendarComponentOptions } from 'ion2-calendar';
import { CalenderPage } from '../calender/calender.page';
import { GlobalService } from '../../../providers/global.service';
import { finalize } from "rxjs/internal/operators/finalize";
import { debounceTime } from "rxjs/operators";
//import { Observable  } from 'rxjs/Rx'
import { Observable, fromEvent } from 'rxjs';
import { MasterService } from '../../../providers/master.service';
import { IonicSelectableComponent } from 'ionic-selectable';

import * as moment from 'moment';

@Component({
    selector: 'app-search-filter',
    templateUrl: './search-filter.page.html',
    styleUrls: ['./search-filter.page.scss'],
})
export class SearchFilterPage implements OnInit {
    private backbuttonSubscription: any;
    pageNo = 1;
    pageSize = 10;
    showStatus = false;
    oldFilters: any = {};
    gs: any = GlobalService;
    selectedRange: any = "";
    mastersConfig: any = [{ Name: "salesperson", Data: [] }, { Name: "location", Data: [] }, { Name: "category", Data: [] }];
    filters: any = { selectedDay: 'Today'};
    optionsRange: CalendarComponentOptions =
    {
        pickMode: 'range',
        from: new Date().setFullYear(new Date().getFullYear() - 1),
        to: new Date()
    };

    _name: string = '<no name set>';

    @Input()
    set name(name: string) {
        // Here you can do what you want with the variable
        this._name = (name && name.trim()) || '<no name set>';
    }
    public unregisterBackAction: any;
    public showDate = true;
    public showDays = true;
    public radiusmiles = 1;
    public days: any = ['Today', 'Tomorrow',  'Last 7 Days', 'Last 30 Days', 'All', 'Custom Range']
    public statuses: string[] = ['All', 'Open', 'Closed', 'Cancelled'];
    public minmaxprice = {
        upper: 500,
        lower: 10
    };

    constructor(private platform: Platform,private modalCtrl: ModalController, public params: NavParams, public masterService:  MasterService) {
        this.filters = GlobalService.selectedFilters;
        this.oldFilters = JSON.parse(JSON.stringify(GlobalService.selectedFilters));
        this.getSelectedDateRange();
        this.showHideFilters(params.get('page'))
    }


    ngOnInit() {
     
        for (let obj of this.mastersConfig) {
            this.getMasterValues(obj);
        }
    }

  
    

    showHideFilters(page) {
        if (page == 'dashboard') {
            this.showDate = true;
            this.showDays = true;
            this.showStatus = false;
        }
        if (page == 'enquirylisting') {
            this.showDate = true;
            this.showDays = true;
            this.showStatus = true;
        }
    }

    async closeModal() {
        //GlobalService.selectedFilters = this.oldFilters;
        //const onClosedData: string = "Cancel";
        await this.modalCtrl.dismiss();
    }

    async ok() {
        this.serializeFilter();
        const onClosedData: string = "OK";
        await this.modalCtrl.dismiss(onClosedData);
    }

    serializeFilter() {
        if (this.filters.selectedDay == 'Today') {
            GlobalService.selectedFilters.FromDate = new Date();
            GlobalService.selectedFilters.ToDate = new Date();
        }
        else if (this.filters.selectedDay == 'Tomorrow') {
            GlobalService.selectedFilters.FromDate = new Date();
            GlobalService.selectedFilters.ToDate = new Date();
            GlobalService.selectedFilters.FromDate.setDate(GlobalService.selectedFilters.FromDate.getDate() + 1);
            GlobalService.selectedFilters.ToDate.setDate(GlobalService.selectedFilters.ToDate.getDate() + 1);
        }
        else if (this.filters.selectedDay == 'Last 7 Days') {
            GlobalService.selectedFilters.FromDate = new Date();
            GlobalService.selectedFilters.ToDate = new Date();
            GlobalService.selectedFilters.FromDate.setDate(GlobalService.selectedFilters.FromDate.getDate() -7);
        }
        else if (this.filters.selectedDay == 'Last 30 Days') {
            GlobalService.selectedFilters.FromDate = new Date();
            GlobalService.selectedFilters.ToDate = new Date();
            GlobalService.selectedFilters.FromDate.setDate(GlobalService.selectedFilters.FromDate.getDate() -60);
        }
        else if (this.filters.selectedDay == 'All') {
            GlobalService.selectedFilters.FromDate = null;
            GlobalService.selectedFilters.ToDate = null;
        }
        else if (this.filters.selectedDay == 'Custom Range') {
            GlobalService.selectedFilters.FromDate = this.filters.dateRange.data.from.format("LLLL");
            GlobalService.selectedFilters.ToDate = this.filters.dateRange.data.to.format("LLLL");;
        }
    }

    getSelectedDateRange() {
        if (this.filters.dateRange) {
            this.selectedRange = this.filters.dateRange.data.from.format("MMM DD, YYYY") + " to " + this.filters.dateRange.data.to.format("MMM DD, YYYY");
        }
    }


    getMasterValues(masterObj) {
        this.pageNo = 2;
        //this.masterService.getData("api/master/get?type=" + masterObj.Name, 1, this.pageSize, null, "").subscribe(list => {
        //    masterObj.Data = list;
        //});

        this.masterService.getData("api/master/get?type=" + masterObj.Name, 1, this.pageSize, null, "").pipe(debounceTime(500),
            finalize(() => {

            }))
            .subscribe(list => {
                masterObj.Data = list;
            },
            err => {

                //GenericService.presentToast(this.toastCtrl, err);
            });
    }

    async  openDatePicker() {
        const modal = await this.modalCtrl.create({
            component: CalenderPage,
            componentProps: { options: this.optionsRange, dateRange: this.filters.dateRange }
        });

        modal.onDidDismiss().then((dateRange) => {
            if (dateRange && dateRange.data && dateRange.data.from)
            this.filters.dateRange = dateRange || this.filters.dateRange;
            this.getSelectedDateRange();
          
        });     
        return await modal.present();
    }
    
}
