import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { Constants } from '../providers/constants';
import { GenericService } from '../providers/generic.service';
import { StorageService } from '../providers/storage.service';


@Injectable()
export class GlobalService
{
    public static User: any = {};
    static selectedFilters = { selectedDay: 'All', FromDate: null, ToDate: null, dateRange: null };
    static spinner;
    static chartConfig =
        {
            valueLabels: true,
            decimalValues: true,
            tabularForm: true
        }

    static categories = [{ Name: "CVOO", ShortName: "sc", Value: 1, Icon: "cvoo.jpg", Brand: [130, 131, 3, 5, 8, 42, 22, 19, 46, 29] }];

    static controlType =
        [
            { Name: "Continues loop", Code: 0 },
            { Name: "One touch", Code: 1 },
            { Name: "Motorised Plug in motor", Code: 2 },
            { Name: "Motorised cordless", Code: 3 }
        ]

    static controlOperation =
        [
            { Name: "Left", Code: 0 },
            { Name: "Right", Code: 1 }            
        ]

    static motorPosition =
        [
            { Name: "Left", Code: 0 },
            { Name: "Right", Code: 1 }
        ]

    static workingOperation = [
        { Code: 0, Name: 'Left Operation' },
        { Code: 1, Name: 'Right Operation' },
        { Code: 2, Name: 'Center Split' }
    ];

    static mattressType = [
        { Code: 0, Name: 'Single' },
        { Code: 1, Name: 'Pair' }
    ];

    static fittingType = [
        { Code: 0, Name: 'Wall' },
        { Code: 1, Name: 'Ceiling Cove' }
    ];

    static lightFiltering = [
        { Code: 0, Name: 'Dim out' },
        { Code: 1, Name: 'Satin' },
        { Code: 2, Name: 'Black out' }
    ];

    static wallType = [
        { Code: 0, Name: 'POP' },
        { Code: 1, Name: 'Concrete' },
        { Code: 2, Name: 'Stone' },
        { Code: 3, Name: 'Wood' }
    ];

    static gatherStyle = [
        { Code: 0, Name: 'Less' },
        { Code: 1, Name: 'Normal' },
        { Code: 2, Name: 'More' }
    ];

    static rodType = [
        { Code: 1, Name: 'ROD' },
        { Code: 2, Name: 'Channel' }
    ];

    static frequency = [{ Name: "Monthly", Value: 1, Selected: true },
    { Name: "Quarterly", Value: 2, Selected: null },
    { Name: "Half Yearly", Value: 3, Selected: null },
    { Name: "Yearly", Value: 4, Selected: null },
    //{ Name: "YTD", Value: 5, Selected: null },
    { Name: "3MMT", Value: 5, Selected: null },
        //{ Name: "6MMT", Value: 6, Selected: null },
    ];
    static blindsOperation = [
        { Code: 0, Name: 'Left' },
        { Code: 1, Name: 'Right' },
        { Code: 2, Name: 'Motorised' }
    ];

    static blindsFittingType = [
        { Code: 1, Name: 'Recess' },
        { Code: 2, Name: 'Face' }
    ];

    static blindsType = [
        { Code: 1, Name: 'ReadymadeBlinds' },
        { Code: 2, Name: 'RomanBlinds' }
    ];

    static blindsWO = [
        { Code: 0, Name: 'Left' },
        { Code: 1, Name: 'Right' },
        { Code: 2, Name: 'Motorised' }
    ];


    static netvariants = [{ Name: "Brands NET", Value: 1, Selected: true },
    { Name: "Brands Variant", Value: 2, Selected: null }
    ];
    static imageryTabs = [{ Name: "Brands (NET/Variants)", Value: 1, index: 0, Selected: true },
    { Name: "Statements", Value: 2, index: 1, Selected: null }
    ];

    static showBrandObservable: number = 0;
    globalArgs: string = "";

    //static segments = [{ Name: "2WO", Value: 1, Group: 1,  Selected: false, Brand: [101,102] },
    //  { Name: "VCOO", Value: 2, Group: 2, Selected: false, Brand: []}
    //    //{ Name: "Deluxe", Value: 3, Selected: false, Brand: '31', '33','35','99','192','46','22','23','36','48','253','250'}       
    //];
    //static segments = [{ name: "entry", value: 1, group:1, selected: false, brand: [25,24, 11, 195, 74] },
    //  { name: "premium less than 200 cc", value: 2, group: 2, selected: false, brand: [242, 14, 21, 37, 49, 65] },
    //  { name: "premium more than 200 cc", value: 3, group: 3,selected: false, brand: [243, 17, 51, 19] },
    //  { name: "deluxe 110 cc", value: 4, group: 4, selected: false, brand: [31, 33, 99, 46, 192] },
    //  { name: "deluxe 125 cc", value: 5, group: 5,selected: false, brand: [23, 36, 48, 250] }
    //];

    static getKeyColors()
    {
        return [
            '#5133AB',
            '#AC193D',
            '#DC572E',
            '#2672EC',
            "#7E3878",
            "#3C763D",
            "#A94442",
            "#FF0097"
        ];
    }

    private static _defaultFilters: any[] = [
        { Value: "wave", Name: "Time Period" },
        //{ Value: "segment", Name: "Segment" },
        //{ Value: "q1", Name: "Center" },
        { Value: "resp_age_groups_1", Name: "Age" },
        { Value: "country10", Name: "Country" },
        //{ Value: "q9", Name: "Age" },
        { Value: "resp_gender", Name: "Gender" },
        { Value: "cota_segment", Name: "Traveler Type" }
    ];

    //brand, filters and country observers are required to update dashboard on value change

    private static _titleObserver = new Subject();
    static titleObservable = GlobalService._titleObserver.asObservable();

    //Show net/ variant filter if (Varint/NET) not selected in cross tab
    //private static _showBrandObserver = new Subject();
    //static showBrandObservable = GlobalService._showBrandObserver.asObservable();

    static changeTitle(newTitle: string)
    {
        this._titleObserver.next(newTitle);
    }

    //static changeShowBrand(showOrNot: number) {
    //  this._showBrandObserver.next(showOrNot);
    //}

    private static _user: any;
    static get user()
    {
        if (this._user)
            return this._user;
        else
        {
            this._user = StorageService.get('user');
            return this._user;
        }
    }

    private static _category: any;
    private static _variant: any;
    static get category()
    {
        return this._category || StorageService.get('categoryies') || this.categories[0];
    }
    static set category(newCategory: any)
    {
        if (this._category != newCategory)
        {
            this._category = newCategory;
            StorageService.set('category', this.category);
            this.emitCategoryChanges();
        }
    }

    private static _netvariant;
    static get netvariant()
    {
        return this._netvariant || StorageService.get('netvariant') || this.netvariants[0];
    }
    static set netvariant(newNetVariant: any)
    {
        if (this._netvariant != newNetVariant)
        {
            this._netvariant = newNetVariant;
            StorageService.set('netvariant', this._netvariant);
            this.emitVariantChanges();
        }
    }

    //FOR PPT IMAGERY TAB............
    private static _imageryTabPPT;
    static get imageryTabPPT()
    {
        return this._imageryTabPPT || StorageService.get('imageryTabPPT');
    }
    static set imageryTabPPT(newImageryTabPPT: any)
    {
        if (this._imageryTabPPT != newImageryTabPPT)
        {
            this._imageryTabPPT = newImageryTabPPT;
            StorageService.set('imageryTabPPT', this._imageryTabPPT);
            this.emitImageryTabPPTChanges();
        }
    }

    //FOR PPT GLOBAL ARGS............
    private static _globalArgPPT;
    static get globalArgPPT()
    {
        return this._globalArgPPT || StorageService.get('globalArgPPT');
    }
    static set globalArgPPT(newGlobalArgPPT: any)
    {
        if (this._globalArgPPT != newGlobalArgPPT)
        {
            this._globalArgPPT = newGlobalArgPPT;
            StorageService.set('globalArgPPT', this._globalArgPPT);
            this.emitImageryTabPPTChanges();
        }
    }

    //FOR PPTCrossTabGroup............
    private static _globalCrossTabGroup;
    static get globalCrossTabGroup()
    {
        return this._globalCrossTabGroup || StorageService.get('globalCrossTabGroup');
    }
    static set globalCrossTabGroup(newGlobalCrossTabGroup: any)
    {
        if (this._globalCrossTabGroup != newGlobalCrossTabGroup)
        {
            this._globalCrossTabGroup = newGlobalCrossTabGroup;
            StorageService.set('globalCrossTabGroup', this._globalCrossTabGroup);
            //this.emitImageryTabPPTChanges();
        }
    }



    //PPT Brand Subbrand
    private static _globalBrandSubbrand;
    static get globalBrandSubbrand()
    {
        return this._globalBrandSubbrand || StorageService.get('globalBrandSubbrand');
    }
    static set globalBrandSubbrand(newGlobalBrandSubbrand: any)
    {
        if (this._globalBrandSubbrand != newGlobalBrandSubbrand)
        {
            this._globalBrandSubbrand = newGlobalBrandSubbrand;
            StorageService.set('globalBrandSubbrand', this._globalBrandSubbrand);
            this.emitImageryTabPPTChanges();
        }
    }




    private static _imageryTabBrandOrStmt;
    static get imageryTabBrandOrStmt()
    {
        return this._imageryTabPPT || StorageService.get('newImageryTabBrandOrStmt');
    }
    static set imageryTabBrandOrStmt(newImageryTabBrandOrStmt: any)
    {
        if (this._imageryTabBrandOrStmt != newImageryTabBrandOrStmt)
        {
            this._imageryTabBrandOrStmt = newImageryTabBrandOrStmt;
            StorageService.set('newImageryTabBrandOrStmt', this._imageryTabBrandOrStmt);
            this.emitImageryTabBrandOrStmtChanges();
        }
    }
    //..........


    private static _categoryObserver = new Subject();
    private static _variantObserver = new Subject();
    private static _imageryPPTTabObserver = new Subject();
    private static _brandSubbrandChangeObserver = new Subject();
    private static _imageryTabBrandOrStmtObserver = new Subject();

    static categoryObservable = GlobalService._categoryObserver.asObservable();
    static emitCategoryChanges() //Here we do not need set function as
    {
        this._categoryObserver.next(this.category);
    }

    static emitVariantChanges() //Here we do not need set function as
    {
        this._variantObserver.next(this.netvariant);
    }
    static emitImageryTabPPTChanges() //Here we do not need set function as
    {
        this._imageryPPTTabObserver.next(this.imageryTabPPT);
    }

    static emitBrandSubbrandChanges() //Here we do not need set function as
    {
        this._brandSubbrandChangeObserver.next(this.globalBrandSubbrand);
    }

    static emitImageryTabBrandOrStmtChanges() //Here we do not need set function as
    {
        this._imageryTabBrandOrStmtObserver.next(this.imageryTabBrandOrStmt);
    }


    private static _brands: any[] = [];
    private static _subbrands: any[] = [];

    static get brands()
    {
        return this._brands || StorageService.get('brands') || this._brands[0];
    }
    static get subbrands()
    {
        return this._subbrands || StorageService.get('subbrands') || this._subbrands[0];
    }

    static set brands(brands: any)
    {
        this._brands = brands || [];
        StorageService.set('brands', this._brands);
        this.emitBrandChanges();
    }

    static set subbrands(subbrands: any)
    {
        this._subbrands = subbrands || [];
        StorageService.set('subbrands', this._subbrands);
        this.emitSubBrandChanges();
    }

    private static _brandsObserver = new Subject();
    private static _subbrandsObserver = new Subject();

    static brandsObservable = GlobalService._brandsObserver.asObservable();
    static subbrandsObservable = GlobalService._subbrandsObserver.asObservable();
    static emitBrandChanges()
    {
        this._brandsObserver.next(this.brands);
    }
    static emitSubBrandChanges()
    {
        this._subbrandsObserver.next(this.subbrands);
    }



    static getSelectedBrands()
    {
        var selectedBrands = this.brands.filter(x => x.Selected);
        if (selectedBrands.length == 0 && this.brands.length > 0)
            selectedBrands = [this.brands[0]];
        return selectedBrands;
    }
    static getSelectedSubBrands()
    {
        var selectedSubBrands = this.subbrands.filter(x => x.Selected);
        if (selectedSubBrands.length == 0 && this.subbrands.length > 0)
            selectedSubBrands = [this.subbrands[0]];
        return selectedSubBrands;
    }

    static getSelectedBrandsCSV()
    {
        var selectedBrands = this.brands.filter(x => x.Selected);
        if (selectedBrands.length == 0 && this.brands.length > 0)
            selectedBrands = [this.brands[0]];
        return selectedBrands.map(x => x.Value).join(',');
    }

    static getSelectedPPTItemsCSV()
    {
        var selectedPPTItems = GlobalService.globalBrandSubbrand.filter(x => x.Selected);
        if (selectedPPTItems.length == 0 && GlobalService.globalBrandSubbrand.length > 0)
            selectedPPTItems = [GlobalService.globalBrandSubbrand[0]];
        return selectedPPTItems.map(x => x.Value).join(',');
    }


    static getSelectedSubBrandsCSV()
    {
        var selectedSubBrands = this.subbrands.filter(x => x.Selected);
        if (selectedSubBrands.length == 0 && this.subbrands.length > 0)
            selectedSubBrands = [this.brands[0]];
        return selectedSubBrands.map(x => x.Value).join(',');
    }


    static getSelectedBrandFrom(brands)
    {
        var res = brands.filter(x => x.Selected);
        if (res || res.length > 0)
            return res[0];
        else
            return {};
    }

    private static _filters: any[];
    static get filters()
    {
        if (!this._filters || this._filters.length == 0)
            this._filters = this._defaultFilters;//StorageService.get('filters') || this._defaultFilters;
        return this._filters;
    }
    static set filters(filters)
    {
        this._filters = filters;
        //StorageService.set('filters', filters);
        this.emitFilterChanges();
    }

    private static _selectedTab: any = 'funnel';
    static get SelectedTab()
    {
        return this._selectedTab;
    }
    static set SelectedTab(filters)
    {
        this._selectedTab = filters;
    }

    private static _filtersObserver = new Subject();
    static filtersObservable = GlobalService._filtersObserver.asObservable();
    static emitFilterChanges()
    {
        this._filtersObserver.next(this.filters);

    }

    private static _segmentsObserver = new Subject();
    static segmentsObserver = GlobalService._segmentsObserver.asObservable();
    static emitSegmentChanges()
    {
        this._segmentsObserver.next(this.filters);
    }



    static loadFilters(masterService)
    {
        masterService.callService("nameValue/list?category=" + GlobalService.category.Value, true)
            .subscribe(
                (data) =>
                {
                    for (let f of GlobalService.filters)
                    {
                        if (GlobalService.category.Value == 2 && f.Name == "Gender")
                            f.Visible = false;
                        else
                            f.Visible = true;
                        f.List = data.filter(x => x.Group == f.Value) || [];
                        f.Selected = f.Selected || [];
                        f.Multiple = f.Multiple == undefined ? true : f.Multiple;
                        f.mode = 'dropdown';
                    }


                    var brands = data.filter(x => x.Group == "Brand") || [];
                    var subbrands = data.filter(x => x.Group == "Subbrand") || [];

                    var i = 0;
                    brands.forEach(brand =>
                    {
                        if (i < 1)
                            brand.Selected = true;
                        else
                            brand.Selected = false;
                        i++;
                    });
                    var j = 0
                    subbrands.forEach(brand =>
                    {
                        if (j < 1)
                            brand.Selected = true;
                        else

                            brand.Selected = false;
                        j++;
                    });

                    GlobalService.brands = brands;
                    GlobalService.subbrands = subbrands;
                });
    }

    static loadBrands(masterService)
    {
        //var selectedSegment = null;
        //var segments = this.segments.filter(x => x.Selected);
        //if (segments.length > 0)
        //    selectedSegment = segments[0];

        masterService.callService("nameValue/list?group=Brand&category=" + GlobalService.category.Value, true)
            .subscribe(
                (data) =>
                {
                    //  var brands = data.filter(x => x.Arg3 == "1" && (selectedSegment.Group == null ? 1 == 1 : x.Arg4 == selectedSegment.Group)) || [];
                    var brands = data.filter(x => x.Arg3 == "1") || [];
                    var i = 0;
                    brands.forEach(brand =>
                    {
                        //if (selectedSegment.Brand.indexOf(parseInt(brand.Value)) > -1)
                        brand.Selected = true;
                        //else
                        //    brand.Selected = false;
                        //i++;
                    });

                    GlobalService.brands = brands;
                });
    }
    static serializeFilters()
    {
        var strFilters = "";
        for (let f of this.filters)
        {
            if (f.List)
            {
                var selectedList = f.List.filter(x => x.Selected);
                if (selectedList.length > 0)
                    strFilters += ";" + f.Value + ":" + GenericService.toCSV(selectedList, 'Value');
            }
        }
        return strFilters;
    }

    static getPageTitleByPath(path: string)
    {
        //for (let item of MENU)
        //{
        //    if (item.routing == path)
        //        return item.title;
        //    if (item.sub)
        //    {
        //        for (let subItem of item.sub)
        //        {
        //            if (subItem.routing == path)
        //                return subItem.title;
        //        }
        //    }
        //}
    }

    static resetFilters()
    {
        for (let f of GlobalService.filters)
        {
            f.List.forEach(itm =>
            {
                itm.Selected = false;
            });
        }
    }

    static getSelectedFrequency()
    {
        var selectedFreq = this.frequency.filter(x => x.Selected);
        if (selectedFreq.length == 0)
            selectedFreq = [this.frequency[0]];
        return selectedFreq[0].Value;
    }

    static showHideFilter(group)
    {
        for (let f of GlobalService.filters)
        {
            if (f.Value == group)
            {
                f.Visible = false;
                if (f.List)
                {
                    f.List.forEach(x =>
                    {
                        x.Selected = false;
                    });
                }
                f.mode = "dropdown";
            }
            else
                f.Visible = true;
        }
    }

    static getMasterName(type, value)
    {

        if (value == "")
            return "";
        var arr = [];
        if (type == "workingoperation")
            arr = this.workingOperation.filter(x => x.Code == value);
        else if (type == "fittingtype")
            arr = this.fittingType.filter(x => x.Code == value);
        else if (type == "lightfiltering")
            arr = this.lightFiltering.filter(x => x.Code == value);
        else if (type == "walltype")
            arr = this.wallType.filter(x => x.Code == value);
        else if (type == "gatherstyle")
            arr = this.gatherStyle.filter(x => x.Code == value);
        else if (type == "rodtype")
            arr = this.rodType.filter(x => x.Code == value);
        else if (type == "blindsFittingType")
            arr = this.blindsFittingType.filter(x => x.Code == value);
        else if (type == "blindsType")
            arr = this.blindsType.filter(x => x.Code == value);
        else if (type == "blindsWO")
            arr = this.blindsWO.filter(x => x.Code == value);
        else if (type == "mattresstype")
            arr = this.mattressType.filter(x => x.Code == value);
        if (arr.length > 0)
            return arr[0].Name;
        else
            return "";
    }

    static getNameCSV(list)
    {
        if (list && list.length > 0)
        {
            return list.map(function (k) { return k["Name"] }).join(",");
        }
        else
            return "";
    }

    static setLabelForDropdown(list, model)
    {
        if (list && list.length > 0)
        {
            model = list.filter(x => x.Code == model.Code);
        }
    }
}
